<template>
  <router-view :key="$route.fullPath" />
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {
    getUserAgentDetails() {
      let screenHeight = window.innerHeight;
      let screenWidth = window.innerWidth;

      let userAgentDetails = {
        screenHeight: screenHeight,
        screenWidth: screenWidth,
        screenType: "",
        device: "",
        os: "",
        browser: "",
      };

      let min_number = 992;

      if (window.innerWidth < min_number) {
        userAgentDetails["screenType"] = "small";
      } else {
        userAgentDetails["screenType"] = "large";
      }

      let userAgent = navigator.userAgent;
      userAgentDetails["os"] = navigator.platform;

      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        userAgentDetails["device"] = "tablet";
        userAgentDetails["screenType"] = "small";
        if (screenWidth > screenHeight && screenWidth > min_number) {
          userAgentDetails["screenType"] = "large";
        }
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          userAgent
        )
      ) {
        userAgentDetails["device"] = "mobile";
        userAgentDetails["screenType"] = "small";
      }

      if (userAgent.split(",")[0] == "AndroidWebApk") {
        userAgentDetails["os"] = "Android";
        userAgentDetails["browser"] = userAgent.split(",")[0];
        userAgentDetails["manufacturer"] = userAgent.split(",")[1];
        userAgentDetails["model"] = userAgent.split(",")[2];
        userAgentDetails["version"] = userAgent.split(",")[3];
      } else if (userAgent.split(",")[0] == "IOSWebApp") {
        userAgentDetails["os"] = "IOS";
      }
      console.log(userAgentDetails);
      return userAgentDetails;
    },
  },
};
</script>

<style>
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.6;
  font-family: "Vazirmatn" !important;
}

p {
  font-family: "Vazirmatn FD" !important;
}

input {
  font-family: "farsi" !important;
}

html {
  overflow-x: hidden !important;
}
/* body {
  overflow-x: hidden !important;
} */
.Vue-Toastification__toast-body {
  font-family: "Vazirmatn";
  direction: rtl;
  text-align: right;
  margin-left: 7px;
  margin-right: 7px;
}
.Vue-Toastification__toast--warning {
  color: var(--bs-gray-900) !important;
}

input {
  border-color: var(--bs-gold-light-transparent) !important;
}

.card-1 {
  background-color: #1e1f25 !important;
  border-radius: 0.75rem !important;
}
.color-title-gold {
  background: -webkit-linear-gradient(top, rgb(239, 177, 0), rgb(125, 59, 2))
    text !important;
  -webkit-text-fill-color: transparent !important;
}

.vpd-input-group input {
  height: 42px !important;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.vpd-body {
  background-color: #131419 !important;
}

.vpd-column-header {
  color: #009688 !important;
}

.vpd-actions button {
  color: #009688 !important;
}

.vpd-simple-content .vpd-addon-list-item {
  border: unset !important;
}

.vpd-selected {
  color: #009688 !important;
  background-color: #212223 !important;
  border-radius: 7px !important;
}

.vpd-icon-btn {
  background-color: var(--bs-gold-light) !important;
}
</style>
